import React from 'react';
import ReactDOM from 'react-dom'
import App from "./component/App";

ReactDOM.render((
    <App />
  ), document.getElementById('root'));

console.log(
    '___________████████__██████\n' +
    '_________█░░░░░░░░██_██░░░░░░█\n' +
    '________█░░░░░░░░░░░█░░░░░░░░░█\n' +
    '_______█░░░░░░░███░░░█░░░░░░░░░█\n' +
    '_______█░░░░███░░░███░█░░░████░█\n' +
    '______█░░░██░░░░░░░░███░██░░░░██\n' +
    '_____█░░░░░░░░░░░░░░░░░█░░░░░░░░███\n' +
    '____█░░░░░░░░░░░░░██████░░░░░████░░█\n' +
    '____█░░░░░░░░░█████░░░████░░██░░██░░█\n' +
    '___██░░░░░░░███░░░░░░░░░░█░░░░░░░░███\n' +
    '__█░░░░░░░░░░░░░░█████████░░█████████\n' +
    '█░░░░░░░░░░█████████████████████████\n' +
    '█░░░░░░░░░░█______█_█____█____█_█___█\n' +
    '█░░░░░░░░░░░░█___███_█__█____███_█___█\n' +
    '░░░░░░░░░░░░░███████████░████████████\n' +
    '░░░░░░░░░░░░░░░░█░░░░░█░░░░░░░░░░░░█\n' +
    '░░░░░░░░░░░░░░░░░░░░██░░░░█░░░░░░██\n' +
    '░░░░░░░░░░░░░░░░░░██░░░░░░░███████\n' +
    '░░░░░░░░░░░░░░░░██░░░░░░░░░░█░░░░░█\n' +
    '░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░█\n' +
    '░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░█\n' +
    '░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░█\n' +
    '░░░░░░░░░░░█████████░░░░░░░░░░░░░░██\n' +
    '░░░░░░░░░░█▒▒▒▒▒▒▒▒███████████████▒▒█\n' +
    '░░░░░░░░░█▒▒███████▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█\n' +
    '░░░░░░░░░█▒▒▒▒▒▒▒▒▒█████████████████\n' +
    '░░░░░░░░░░████████▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█\n' +
    '░░░░░░░░░░░░░░░░░░██████████████████\n' +
    '░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░█\n' +
    '██░░░░░░░░░░░░░░░░░░░░░░░░░░░██\n' +
    '▓██░░░░░░░░░░░░░░░░░░░░░░░░██\n' +
    '▓▓▓███░░░░░░░░░░░░░░░░░░░░█\n' +
    '▓▓▓▓▓▓███░░░░░░░░░░░░░░░██\n' +
    '▓▓▓▓▓▓▓▓▓███████████████▓▓█\n' +
    '▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓██\n' +
    '▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓█');