import React from 'react'

const Software = () => (
  <>
    <h1>My software journey</h1>
    <h2>Background</h2>
    <p>
      I received a B.S. in Computer Science from University of California,
      San Diego in 2013.
    </p>
    <p>
      I've been working professionally since then with some internships
      during my studies. I'm most comfortable with developing full stack
      web applications - touching database, API, UI layers.
    </p>
    <h2>Notable experiences</h2>
    <h3>Software Engineering Contractor at Google 2014 - 2018</h3>
    <p>
      Working out of a small team full of Google employees, I worked on an
      internal web application used by Google Doubleclick to generate
      invoices from their contracts.
    </p>
    <p>
      From this experience I learned about full stack web applications with
      Angular.js, Google Servlet Engine, and Google Dremel. I was able to get
      invaluable experience with Google engineers. My coding became refined
      through code reviews and got experience on writing technical documents.
    </p>
    <h3>
      Senior Software Engineer (IC-3) at Oracle Cloud Infrastructure
      2018 - Present
    </h3>
    <p>
      Worked on a big team of software engineers under the Security org at
      Oracle Cloud Infrastructure. I worked on the infrastructure which
      controlled internal access to resources like SSH to hosts, Jira, Grafana,
      Bitbucket, Teamcity with 2FA through Yubikey. For the first year, I
      worked on the backend infrastructure side with a good portion of it on
      building our service out for new regions. For the second year, I
      switched to doing full stack web application types of work with our
      internal tool on managing access.
    </p>
    <p>
      This was the first time where I worked with a big team of engineers. The
      pace was a lot more fast and grungy for the development environment. I
      got to work with SSH, LDAP, OpenSSL, ReactJS, Java Spring, and Oracle DB.
      I helped with the code reviews, design reviews, and general coding
      questions on slack.
    </p>
    <h2>Awards</h2>
    <p>
      Winner of&nbsp;
      <a
          target="_blank"
          rel="noreferrer"
          href="https://devpost.com/software/dolladollabillyo">
        Hero City Hacks Hackathon
      </a>.
    </p>
    <h2>Apps</h2>
    <p>
      <a
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.louis.minesweeper">
        Minesweeper
      </a>
    </p>
  </>
);

export default Software;
