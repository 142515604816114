import React from "react";
import Art from "./Art";
import Blog from "./Blog";
import BlogPost from "./BlogPost";
import Books from "./Books";
import Software from "./Software";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import ReactTooltip from 'react-tooltip';
import "../css/App.css";
import "../css/App.mobile.css";

export default class App extends React.PureComponent {
  render() {
    let year = new Date().getFullYear();
    return (
      <Router>
        <header>
          👨‍💻
          <Link to="/">Louis' Blog</Link>|📄
          <Link to="/software">Resume</Link>|📚
          <Link to="/books">Fave Books</Link>|🎨
          <Link to="/art">Art</Link>
        </header>
        <div className="content">
          <Switch>
              <Route path="/software" exact component={Software} />
              <Route path="/books" exact component={Books} />
              <Route path='/art' component={Art} />
              <Route path='/:blogPost' component={BlogPost} /> { /* The catch all */ }
              <Route path='' component={Blog} />
          </Switch>
        </div>
        <footer>
          Copyright {year} Louis Wong&nbsp;
          <a className={'social-icon fa fa-linkedin fa-1x'}
              target="_blank"
              href="https://www.linkedin.com/in/louiswong5"></a>|
          <CopyToClipboard text="louis1204@gmail.com"
            onCopy={() => ToastsStore.info("Email copied")}>
            <span data-tip="Click to copy">louis1204@gmail.com</span>
          </CopyToClipboard>
          <ReactTooltip place="top" type="dark" effect="solid"/>
          <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore}/>
        </footer>
      </Router>
    );
  }
}
