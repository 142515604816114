import React from 'react'

const Books = () => (
  <>
    <h1>Books that have changed my life</h1>
    <h2><i>How to Win Friends and Influence People</i></h2>
    <h4>Author: Dale Carnegie</h4>
    <p>
      Struggling with shyness in my childhood, this book helped me with
      talking to others. Methods like remembering and talking about one's
      name, having a balance of talking about the other person and yourself,
      listening to what the other person has to say, starting with something
      positive before a negative (without the use of the word "but"), and
      understanding that the easiest way to get someone to do something you
      want is to make them see what's in it for them.
    </p>
    <h2><i>Outwitting the Devil</i></h2>
    <h4>Author: Napoleon Hill</h4>
    <p>
      The book is just a dialog between the author and the devil. He reveals
      all of the devil's methods for taking control of a person's life while on
      Earth. This book helped me understand the pitfalls that people do that
      surrender their life to the devil - things like smoking, not exercising,
      bad financials, bad habits, and not having control over your thoughts and
      your life will make you a "drifter" in this world. To be a non-"drifter"
      you need to live a life of definite purpose.
    </p>
    <h2><i>One Piece</i></h2>
    <h4>Author: Eichiiro Oda</h4>
    <p>
      This is a manga comic that I've been following since my youth. There are
      many inspirational moments of overcoming challenges and friendship. I've
      received a lot of motivation from this comic series and it has helped me
      through many times when I just wanted to give up - particularly in my
      undergrad life.
    </p>
    <h2><i>Tao Te Ching</i></h2>
    <h4>Author: Lao Tzu</h4>
    <p>
      This is a classical text written by a Chinese philosopher and is the
      cornerstone of the philosophy of Taoism. This text helped me see the
      world differently in relation to me. I am just a part of this universe
      and the path of the universe is the "Tao". From this, I see the world
      detached from myself and as a whole and trust in its path, while staying
      centered in the Tao.
    </p>
    <h2><i>1984</i></h2>
    <h4>Author: George Orwell</h4>
    <p>
      This book is often quoted in context of technology and where society is
      trending towards. Usually quoted in protest to monitoring and big data
      on people. I think this book is also beautifully written and portrays two
      differents lives in a dystopian future. One that abides by the party and
      lives a decent but muted life vs one that is free and vibrant. I often
      think about how people can be consumed by despotism and how easily it can
      be corrupted.
    </p>
    <h1>SWE books that have somewhat changed my life</h1>
    <h2><i>Clean Code</i></h2>
    <h4>Author: Bob C. Martin</h4>
    <p>
      A staple book on writing clean code. Style is usually based on best
      practices and there's no hard set rules unless it's baked in the
      language. I think style is extremely important for code longevity as
      maintaining and future developer's ability to build upon the code is
      dependent on it.
    </p>
  </>
);

export default Books;
