import * as contentful from 'contentful'

class ContentfulUtil {
  static client = contentful.createClient({
    space: 'u58p95nm3qik',
    accessToken: 'u_ySW_bYWikV7QeBj7vAIzZrRyhIY-lZJihZj3F70S4'
  })
  
  static fetchAllBlogPosts = () => ContentfulUtil.client.getEntries({
      content_type: 'blog',
      order: '-sys.createdAt'
    })

  static fetchPostByPathName = (pathName) => ContentfulUtil.client.getEntries({
      content_type: 'blog',
      'fields.path[in]': pathName
    })  
}
export default ContentfulUtil;