import React from 'react'
import ThreeEntryPoint from '../js/ThreeEntryPoint'

class Art extends React.Component {
  componentDidMount() {
    ThreeEntryPoint();
  }

  render() {
    return (
      <>
        <h1>Some of my visual creations</h1>
        <h2><i>Init</i></h2>
        <h3>2021</h3>
        <canvas id="bg" style={{width: "80%", height: "80%"}}></canvas>
      </>
    );
  }
}

export default Art;